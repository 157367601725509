import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.22.0/firebase-app.js';
import { getMessaging, isSupported, onBackgroundMessage } from 'https://www.gstatic.com/firebasejs/9.22.0/firebase-messaging-sw.js';

const app = initializeApp({
  projectId:"pdcapp-acf0f",
  appId:"1:1015872408157:web:1dc8a65c344e371ad95bb4",
  storageBucket:"pdcapp-acf0f.appspot.com",
  apiKey:"AIzaSyACjT9Gih13reYWMW5X2-SEsMtETulWIzE",
  authDomain:"pdcapp-acf0f.firebaseapp.com",
  messagingSenderId:"1015872408157",
  measurementId:"G-9L9SMYBFER",
});

isSupported().then(isSupported => {

  if (isSupported) {

    const messaging = getMessaging(app);
    console.log(isSupported)

    self.addEventListener('notificationclick', function(event) {
      event.notification.close();
  
      var promise = new Promise(function(resolve) {
        setTimeout(resolve, 1000);
      }).then(function() {
        return clients.openWindow(event.data.locator);
      });
  
      event.waitUntil(promise);
    });


    self.addEventListener('push', function(event) {
      console.log(event)
    });
    onBackgroundMessage(messaging, ({ notification: { title, body, image } }) => {
      console.log(title)
      self.registration.showNotification(title, { body, icon: image || '/assets/icons/icon-72x72.png' });
    });

  }

});
